import React, { Component } from "react";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import "./Site_name";
//import './Style.css';

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      counters: [],
      site: global.site_name,
    };
  }

  componentDidMount() {
    fetch(
      `https://admin-foodweb-laravel.ksoftpl.com/api/home/${this.state.site}`
    ).then((resp) => {
      resp.json().then((result) => {
        // console.warn(result)
        this.setState({ counters: result.data.contact });
      });
    });
  }

  render() {
    const { counters } = this.state;
    return (
      <div class="footer">
        <div className="sub">
          <div class="wrap">
            <img
              src="/assets/images/logo.png"
              alt="footer logo"
              className="img center-block footer_logo"
              style={{ height: 80 }}
            />
          </div>
          <div class="wrap">
            <div>
              <PersonIcon />
              <span> Contact Manager: {counters.c_contact_manger_name}</span>
            </div>
            <div>
              <PhoneIphoneIcon />
              <span> Mobile Number: {counters.c_mobile_no}</span>
            </div>
          </div>
          <div class="wrap">
            <div>
              <EmailIcon />
              <span> {counters.c_email}</span>
            </div>
            <div>
              <PhoneIcon />
              <span> Desk Number:{counters.c_desk_number}</span>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="copyright">
          <a href="https://kanishkasoftware.com/" target="_blank">
            Designed & Developed by Kanishka Software Pvt. Ltd.
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
